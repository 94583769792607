declare const gtag: (...args: any[]) => void;

window.addEventListener('load', function () {
    const tags = document.head.querySelector('meta[name="ga_tags"]')?.content;
    tags?.split(',').forEach(tag => {
        if (tag.length) {
            gtag('config', tag);
        }
    });

    const event = new Event('gtags-ready');
    window.dispatchEvent(event);


}, { passive: true });
